
.action-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.userGrid_edit .edit-button {
    cursor: pointer;
    color: #0288d1;
}

.dhx_demo-grid .remove-button {
    cursor: pointer;
    color: red;
}

.dhxUserWindow .dhx_window-footer,
.dhxUserWindow2 .dhx_window-footer,
.dhxUserWindow3 .dhx_window-footer{
    padding-top: 0;
}

.dhxUserWindow .dhx_window-content,
.dhxUserWindow2 .dhx_window-content,
.dhxUserWindow3 .dhx_window-content{
    padding-bottom: 0;
}

.dhxUserWindow .dhx_window-header,
.dhxUserWindow2 .dhx_window-header,
.dhxUserWindow3 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

body{
    letter-spacing:0 !important;
}
