.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.custom-class .dhx_tabbar-tab-button {
    background-color: rgb(234, 238, 238);
    color: #000000;
    border-color: #b4b6b6;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.custom-class .dhx_tabbar-tab-button--active {
    background-color: rgb(2, 136, 209);
    color: white;
}
.custom-class .dhx_tabbar-header-active {
    /*background-color: #ff5252;*/
}
.stopFormTitle input{
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}

.testgroup > .dhx_form-element{
    flex: 1 1 auto !important;
}

.reserveWindow1 .dhx_form-group{
    margin-bottom: 3px;
}

.reserveWindow1 .dhx_window-footer,
.dhxReserveWindow2 .dhx_window-footer{
    padding-top: 0;
}

.reserveWindow1 .dhx_window-content,
.dhxReserveWindow2 .dhx_window-content{
    padding-bottom: 0;
}

.reserveWindow1 .dhx_window-header,
.reserveWindow1 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.reserveForm_tip input{
    padding: 0px;
    color: red;
}