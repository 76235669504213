.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.stopFormTitle input{
    color: rebeccapurple;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}
.testgroup > .dhx_form-element{
    flex: 1 1 auto !important;
}