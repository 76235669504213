.dhxHousekeepingReportWindow1 .dhx_window-footer{
    padding-top: 0;
}

.dhxHousekeepingReportWindow1 .dhx_window-content{
    padding-bottom: 0;
}

.dhxHousekeepingReportWindow1 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}