.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.custom-class .dhx_tabbar-tab-button {
    background-color: rgb(234, 238, 238);
    color: #000000;
    border-color: #b4b6b6;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.custom-class .dhx_tabbar-tab-button--active {
    background-color: rgb(2, 136, 209);
    color: white;
}
.custom-class .dhx_tabbar-header-active {
    /*background-color: #ff5252;*/
}
.stopFormTitle input{
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}

.testgroup > .dhx_form-element{
    flex: 1 1 auto !important;
}

.priceRightMargin{
    margin: 0 4px;
}

.priceRightMargin label{
    text-align: center;
    margin-bottom: 0px;
}

.priceRightMargin input{
    height: 23px;
    padding: 2px;
}

.pricesSetGridFormWindow1 .dhx_form-group{
    margin-bottom: 0;
}

.pricesSetGridFormWindow3 .dhx_form-group{
    margin-bottom: 3px;
}

.pricesSetGridFormWindow1 input, .pricesSetGridFormWindow1 select{
    padding: 3px;
}

.pricesSetGridFormWindow1 .dhx_window-header,
.pricesSetGridFormWindow3 .dhx_window-header,
.pricesSetGridFormWindow4 .dhx_window-header,
.pricesSetGridFormWindow5 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.pricesSetGridFormWindow1 .dhx_window-footer,
.pricesSetGridFormWindow3 .dhx_window-footer,
.pricesSetGridFormWindow4 .dhx_window-footer,
.pricesSetGridFormWindow5 .dhx_window-footer{
    padding-top: 0;
}

.pricesSetGridFormWindow1 .dhx_window-content,
.pricesSetGridFormWindow3 .dhx_window-content,
.pricesSetGridFormWindow4 .dhx_window-content,
.pricesSetGridFormWindow5 .dhx_window-content{
    padding-bottom: 0;
}

.pricesSetGridFormWindow1 label,.pricesSetGridFormWindow3 label{
    margin-bottom: 0px;
}

.pricesSetGridFormWindow3 .dhx_form-group--inline .dhx_label{
    margin-right: 0px;
}

.table{
    width: 4500px;
    text-align: center;
    border-collapse: collapse;
}

.table th, .table td{
    width: 7.5rem;
    border: #8d8f91 1px solid;
}

.table_div{
    width: auto;
    /*max-width: 4500px;*/
    height: auto;
    overflow: auto;
}

thead{
    background-color: #f1f1f1;
}

tbody{
    background-color: #fffde1;
}

tbody tr:nth-child(odd){
    background-color: #fffde1;
}

tbody tr:nth-child(even){
    background-color: #ebffe1;
}