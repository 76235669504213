.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.custom-class .dhx_tabbar-tab-button {
    background-color: rgb(234, 238, 238);
    color: #000000;
    border-color: #b4b6b6;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.custom-class .dhx_tabbar-tab-button--active {
    background-color: rgb(2, 136, 209);
    color: white;
}
.custom-class .dhx_tabbar-header-active {
    /*background-color: #ff5252;*/
}
.stopFormTitle input{
    color: #cea26c;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}

.prdSelectGroup > .dhx_form-element{
    flex: 1 1 auto !important;
}

.row1{
    border: 1px #dadada solid;
    padding: 5px;
    padding-top: 10px;
    /*margin-bottom: 20px;*/
}

.row1_required{
    border: 1px #e78b8b solid;
}

.dhxOtherPrdBasSetGridWindow1 label{
    margin-bottom: 0px;
}

.dhxOtherPrdBasSetGridWindow1 .dhx_form-group{
    margin-bottom: 3px;
}

.dhxOtherPrdBasSetGridWindow2 label{
    margin-bottom: 0px;
}

.dhxOtherPrdBasSetGridWindow2 .dhx_form-group{
    margin-bottom: 3px;
}

.dhxOtherPrdBasSetGridWindow1 .dhx_window-footer, .dhxOtherPrdBasSetGridWindow2 .dhx_window-footer, .dhxOtherPrdBasSetGridWindow3 .dhx_window-footer{
    padding-top: 0;
}

.dhxOtherPrdBasSetGridWindow1 .dhx_window-content, .dhxOtherPrdBasSetGridWindow2 .dhx_window-content, .dhxOtherPrdBasSetGridWindow3 .dhx_window-content{
    padding-bottom: 0;
}

.dhxOtherPrdBasSetGridWindow1 .dhx_window-header, .dhxOtherPrdBasSetGridWindow2 .dhx_window-header, .dhxOtherPrdBasSetGridWindow3 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}