.dhxUnitsSetWindow .dhx_window-footer,
.dhxUnitsSetWindow2 .dhx_window-footer,
.dhxUnitsSetWindow3 .dhx_window-footer,
.dhxHotelVerbSetWindow .dhx_window-footer,
.dhxHotelVerbSetWindow2 .dhx_window-footer,
.dhxHotelVerbSetWindow3 .dhx_window-footer,
.hotelMaintainTeamWindow .dhx_window-footer,
.hotelMaintainTeamWindow2 .dhx_window-footer,
.hotelFaultSetWindow .dhx_window-footer,
.hotelFaultSetWindow2 .dhx_window-footer,
.hotelBookingCancelWindow .dhx_window-footer,
.hotelBookingCancelWindow2 .dhx_window-footer,
.mainProductsSetWindow .dhx_window-footer,
.mainProductsSetWindow2 .dhx_window-footer,
.serviceTypeWindow .dhx_window-footer,
.dhxOrderSourceSetWindow .dhx_window-footer,
.dhxOrderSourceSetWindow2 .dhx_window-footer,
.dhxOrderSourceSetWindow3 .dhx_window-footer,
.dhxOrderSubSourceSetWindow .dhx_window-footer,
.dhxOrderSubSourceSetWindow2 .dhx_window-footer,
.dhxOrderSubSourceSetWindow3 .dhx_window-footer{
    padding-top: 0;
}

.dhxUnitsSetWindow .dhx_window-content,
.dhxUnitsSetWindow2 .dhx_window-content,
.dhxUnitsSetWindow3 .dhx_window-content,
.dhxHotelVerbSetWindow .dhx_window-content,
.dhxHotelVerbSetWindow2 .dhx_window-content,
.dhxHotelVerbSetWindow3 .dhx_window-content,
.hotelMaintainTeamWindow .dhx_window-content,
.hotelMaintainTeamWindow2 .dhx_window-content,
.hotelFaultSetWindow .dhx_window-content,
.hotelFaultSetWindow2 .dhx_window-content,
.hotelBookingCancelWindow .dhx_window-content,
.hotelBookingCancelWindow2 .dhx_window-content,
.mainProductsSetWindow .dhx_window-content,
.mainProductsSetWindow2 .dhx_window-content,
.serviceTypeWindow .dhx_window-content,
.dhxOrderSourceSetWindow .dhx_window-content,
.dhxOrderSourceSetWindow2 .dhx_window-content,
.dhxOrderSourceSetWindow3 .dhx_window-content,
.dhxOrderSubSourceSetWindow .dhx_window-content,
.dhxOrderSubSourceSetWindow2 .dhx_window-content,
.dhxOrderSubSourceSetWindow3 .dhx_window-content{
    padding-bottom: 0;
}

.dhxUnitsSetWindow .dhx_window-header,
.dhxUnitsSetWindow2 .dhx_window-header,
.dhxUnitsSetWindow3 .dhx_window-header,
.dhxHotelVerbSetWindow .dhx_window-header,
.dhxHotelVerbSetWindow2 .dhx_window-header,
.dhxHotelVerbSetWindow3 .dhx_window-header,
.hotelMaintainTeamWindow .dhx_window-header,
.hotelMaintainTeamWindow2 .dhx_window-header,
.hotelFaultSetWindow .dhx_window-header,
.hotelFaultSetWindow2 .dhx_window-header,
.hotelBookingCancelWindow .dhx_window-header,
.hotelBookingCancelWindow2 .dhx_window-header,
.mainProductsSetWindow .dhx_window-header,
.mainProductsSetWindow2 .dhx_window-header,
.serviceTypeWindow .dhx_window-header,
.dhxOrderSourceSetWindow .dhx_window-header,
.dhxOrderSourceSetWindow2 .dhx_window-header,
.dhxOrderSourceSetWindow3 .dhx_window-header,
.dhxOrderSubSourceSetWindow .dhx_window-header,
.dhxOrderSubSourceSetWindow2 .dhx_window-header,
.dhxOrderSubSourceSetWindow3 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.hotelVerbForm_tip input{
    padding: 0px;
    color: red;
}