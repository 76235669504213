.stopFormTitle input{
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}
.row1{
    border: 1px #dadada solid;
    padding: 5px;
    padding-top: 10px;
    /*margin-bottom: 20px;*/
}
.row1_title{
    position: relative;
    background-color: transparent;
    top: 15px;
    left: 10px;
    width: 300px;
}
.row1_title .dhx_form-group{
    margin-bottom: 0;
}

.row1_title>.dhx_layout-cell{
    background-color: transparent;
}
input[name="row1_title"]{
    background-color: #ffffff;
    text-align: center;
    color: #c5c4c4;
    padding-right: 0;
}
.row2{
    border: 1px #dadada solid;
    padding: 5px;
    padding-top: 10px;
    margin-bottom: 5px;
}
.row2_title{
    position: relative;
    background-color: transparent;
    top: 15px;
    left: 10px;
    width: 300px;
}
.row2_title .dhx_form-group{
    margin-bottom: 0;
}
.row2_title>.dhx_layout-cell{
    background-color: transparent;
}
input[name="row2_title"]{
    background-color: #ffffff;
    text-align: center;
    color: #c5c4c4;
    padding-right: 0;
}
/*.samePer{*/
/*    border-top: 1px #8d8f91 solid;*/
/*    padding-top: 10px;*/
/*}*/
.guest{
    border-top: 1px #8d8f91 solid;
    padding-top: 10px;
}
/*#orderDeleteFormImg_div{*/
/*    background-image: url("../src/assets/images/error.png");*/
/*}*/
.viewOrderDetail{
    text-decoration: blue;
}

.orderInfo_orderInfo>.orderForm{
    padding: 0px !important;
}

.orderFormNote{
    margin-top: 18px;
}

.orderFormNote label{
    /*margin-top: 15px;*/
    font-weight: bold;
}

.orderInfo_guestGridToolbar .guestGridToolbarTitle span{
    margin-left: 0px !important;
    font-weight: bold;
}

.dhxOrderInfo_purchaserGridWindow1 .dhx_window-header,
.dhxOrderInfo_guestGridWindow1 .dhx_window-header,
.dhxOrderInfo_guestGridWindow2 .dhx_window-header,
.dhxProductInfo_roomGridWindow1 .dhx_window-header,
.dhxProductInfo_roomGridWindow2 .dhx_window-header,
.dhxAlertSetGridWindow1 .dhx_window-header,
.dhxAlertSetGridWindow2 .dhx_window-header,
.dhxProductInfo_otherPrdGridWindow1 .dhx_window-header,
.dhxProductInfo_otherPrdGridWindow2 .dhx_window-header,
.dhxProductInfo_payLogGridWindow1 .dhx_window-header,
.dhxProductInfo_payLogGridWindow2 .dhx_window-header,
.dhxProductInfo_sendNewPayLinkFormWindow1 .dhx_window-header,
.dhxOrderWindow2 .dhx_window-header,
.dhxOrderWindow1 .dhx_window-header{
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.dhxOrderInfo_purchaserGridWindow1 .dhx_window-footer,
.dhxOrderInfo_guestGridWindow1 .dhx_window-footer,
.dhxOrderInfo_guestGridWindow2 .dhx_window-footer,
.dhxProductInfo_roomGridWindow1 .dhx_window-footer,
.dhxProductInfo_roomGridWindow2 .dhx_window-footer,
.dhxAlertSetGridWindow1 .dhx_window-footer,
.dhxAlertSetGridWindow2 .dhx_window-footer,
.dhxProductInfo_otherPrdGridWindow1 .dhx_window-footer,
.dhxProductInfo_otherPrdGridWindow2 .dhx_window-footer,
.dhxProductInfo_payLogGridWindow1 .dhx_window-footer,
.dhxProductInfo_payLogGridWindow2 .dhx_window-footer,
.dhxProductInfo_sendNewPayLinkFormWindow1 .dhx_window-footer,
.dhxOrderWindow2 .dhx_window-footer,
.dhxOrderWindow1 .dhx_window-footer{
    padding-top: 0;
}

.dhxOrderInfo_purchaserGridWindow1 .dhx_window-content,
.dhxOrderInfo_guestGridWindow1 .dhx_window-content,
.dhxOrderInfo_guestGridWindow2 .dhx_window-content,
.dhxProductInfo_roomGridWindow1 .dhx_window-content,
.dhxProductInfo_roomGridWindow2 .dhx_window-content,
.dhxAlertSetGridWindow1 .dhx_window-content,
.dhxAlertSetGridWindow2 .dhx_window-content,
.dhxProductInfo_otherPrdGridWindow1 .dhx_window-content,
.dhxProductInfo_otherPrdGridWindow2 .dhx_window-content,
.dhxProductInfo_payLogGridWindow1 .dhx_window-content,
.dhxProductInfo_payLogGridWindow2 .dhx_window-content,
.dhxProductInfo_sendNewPayLinkFormWindow1 .dhx_window-content,
.dhxOrderWindow2 .dhx_window-content,
.dhxOrderWindow1 .dhx_window-content{
    padding-bottom: 0;
}

.dhxOrderInfo_guestGridWindow2 .guestGrid_deleteForm .deleteLabel .dhx_form-group,
.dhxOrderInfo_guestGridWindow2 .guestGrid_deleteForm .deleteLabel .dhx_form-group .dhx_label,
.dhxOrderInfo_guestGridWindow2 .guestGrid_deleteForm .deleteLabel .dhx_form-group--textinput,
.dhxAlertSetGridWindow2 .alertSetGrid_deleteForm .deleteLabel .dhx_form-group,
.dhxAlertSetGridWindow2 .alertSetGrid_deleteForm .deleteLabel .dhx_form-group .dhx_label,
.dhxAlertSetGridWindow2 .alertSetGrid_deleteForm .deleteLabel .dhx_form-group--textinput{
    margin-bottom: 0px;
}

.dhxOrderInfo_guestGridWindow2 .guestGrid_deleteForm .deleteLabel .dhx_form-group--textinput input,
.dhxAlertSetGridWindow2 .alertSetGrid_deleteForm .deleteLabel .dhx_form-group--textinput input{
    padding-top: 0px;
}

.grid_pay{
    background: #d3ffae;
}

.grid_unpay{
    background: rgb(255, 234, 127);
}

.grid_delete{
    background: rgb(255, 150, 127);
}

.room_noRoom{
    background: rgb(255, 150, 127);
}

.dhxProductInfo_roomGridWindow1 .total .dhx_form-group,
.newOrder_roomGridWindow1 .total .dhx_form-group{
    margin-bottom: 0px;
    background-color: yellow;
}

.dhxProductInfo_roomGridWindow1 .total .dhx_form-group input,
.newOrder_roomGridWindow1 .total .dhx_form-group input{
    text-align: right;
    font-weight: bold;
    font-size: 1.2rem;
}

.noLabel .dhx_label{
    height: 20px;
}

/*.noLabel{*/
/*    text-align: center;*/
/*}*/


.newOrder_restRoomGridForm_Radio{
    /*margin-top: 10px;*/
}
/*.smartBookingForm_Radio .dhx_label {*/
/*    margin: 0px;*/
/*}*/

.highlight-date {
    color: #fff;
}

.highlight-date:after {
    background-color: #a2c1f6;
    opacity: 1;
    z-index: -3;
}

.dhx_message{
    z-index: 999;
}

.gridTitle{
    padding-top: 16px;
    padding-left: 16px;
}
