.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.stopFormTitle input{
    /*color: rebeccapurple;*/
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}
.testgroup > .dhx_form-element{
    flex: 1 1 auto !important;
}
.dhxCheckIn_purchaserGridWindow1 .dhx_window-footer,
.dhxViewGridWindow1 .dhx_window-footer,
.dhxCheckOutWindow1 .dhx_window-footer,
 .dhxNewOrder_payLogGridWindow1 .dhx_window-footer,
.dhxViewGridWindow2 .dhx_window-footer,
.dhxLostPropertyFormWindow1 .dhx_window-footer,
.dhxCheckIn_payLogGridWindow1 .dhx_window-footer,
.dhxCheckIn_payLogGridWindow2 .dhx_window-footer,
.changeRoomFormWindow1 .dhx_window-footer,
.dhxOrderWindow1 .dhx_window-footer,
.dhxOrderWindow2 .dhx_window-footer,
.dhxNewOrderWindow1 .dhx_window-footer,
.dhxCheckIn_canCheckInRoomGridWindow1 .dhx_window-footer,
.dhxCheckOut_canCheckOutRoomGridWindow1 .dhx_window-footer,
.dhxCheckInWindow .dhx_window-footer{
    padding-top: 4px;
}

.dhxCheckIn_purchaserGridWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxViewGridWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxCheckOutWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxNewOrder_payLogGridWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxViewGridWindow2 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxLostPropertyFormWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxCheckIn_payLogGridWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxCheckIn_payLogGridWindow2 .dhx_window-footer .windowFootAccept .dhx_button,
.changeRoomFormWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxOrderWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxOrderWindow2 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxNewOrderWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxCheckIn_canCheckInRoomGridWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxCheckOut_canCheckOutRoomGridWindow1 .dhx_window-footer .windowFootAccept .dhx_button,
.dhxCheckInWindow .dhx_window-footer .windowFootAccept .dhx_button{
    margin-right: 0px;
}

.dhxCheckIn_purchaserGridWindow1 .dhx_window-content,
.dhxViewGridWindow1 .dhx_window-content,
.dhxCheckOutWindow1 .dhx_window-content,
.dhxNewOrder_payLogGridWindow1 .dhx_window-content,
.dhxViewGridWindow2 .dhx_window-content,
.dhxLostPropertyFormWindow1 .dhx_window-content,
.dhxCheckIn_payLogGridWindow1 .dhx_window-content,
.dhxCheckIn_payLogGridWindow2 .dhx_window-content,
.changeRoomFormWindow1 .dhx_window-content,
.dhxOrderWindow1 .dhx_window-content,
.dhxOrderWindow2 .dhx_window-content,
.dhxNewOrderWindow1 .dhx_window-content,
.dhxCheckIn_canCheckInRoomGridWindow1 .dhx_window-content,
.dhxCheckOut_canCheckOutRoomGridWindow1 .dhx_window-content,
.dhxCheckInWindow .dhx_window-content{
    padding-bottom: 0;
}

.dhxViewGridWindow1 .dhx_window-content .dhx_form{
    padding: 0 !important;
}

.dhxCheckIn_purchaserGridWindow1 .dhx_window-header,
.dhxViewGridWindow1 .dhx_window-header,
.dhxCheckOutWindow1 .dhx_window-header,
.dhxNewOrder_payLogGridWindow1 .dhx_window-header,
.dhxViewGridWindow2 .dhx_window-header,
.dhxLostPropertyFormWindow1 .dhx_window-header,
.dhxCheckIn_payLogGridWindow1 .dhx_window-header,
.dhxCheckIn_payLogGridWindow2 .dhx_window-header,
.changeRoomFormWindow1 .dhx_window-header,
.dhxOrderWindow1 .dhx_window-header,
.dhxOrderWindow2 .dhx_window-header,
.dhxNewOrderWindow1 .dhx_window-header,
.dhxCheckIn_canCheckInRoomGridWindow1 .dhx_window-header,
.dhxCheckOut_canCheckOutRoomGridWindow1 .dhx_window-header,
.dhxCheckInWindow .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.dhxViewGridWindow2 .dhx_window-content-without-header{
    padding: 10px;
}

.dhxViewGridWindow2 .dhx_window .dhx_window-footer .dhx_toolbar .dhx_navbar{
    padding: 0px;
}

.changeBtn button{
    width: 100%;
    font-size: 20px;
}

.dhxMonthViewGrid .dhx_grid-header-cell-text_content,
.dhxMonthViewGrid .dhx_grid-cell,
.dhxWeekViewGrid .dhx_grid-header-cell-text_content,
.dhxWeekViewGrid .dhx_grid-cell,
.dhxDayViewGrid .dhx_grid-header-cell-text_content,
.dhxDayViewGrid .dhx_grid-cell{
    font-size: 12px;
}

.status{
    padding: 8px 7px;
    margin: 5px;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.625rem 0.9375rem -0.1875rem rgba(0, 0, 0, 0.1), 0rem 0.25rem 0.375rem -0.125rem rgba(0, 0, 0, 0.05);
    font-size: 1rem;
    font-weight: 800;
    display: inline-block;
}

.status_text{
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.375;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #344767;
    font-weight: 700;
    letter-spacing: 0em;
    padding: 16px 0px 16px 16px;
    display: inline-block;
}

.viewGridForm2Header .dhx_form-group,
.newOrder_purchaserInfoForm .dhx_form-group{
    margin-bottom: 0px;
}

.viewGridForm2Header .dhx_form-group label{
    margin: 0px;
    padding: 6px 0px;
}

.viewGridForm2Header .dhx_form-group input,
.viewGridForm2Header .dhx_form-group label{
    font-size: 16px;
}

.dhxViewGridWindow2 .dhx_layout-cell-header{
    padding:0px;
}
.reportLosyPropertyFormTitle input{
    color: #3a5b94;
    font-weight: 800;
    text-align: center;
    font-size: 1.5em;
    padding: 0px;
}

.reportLosyPropertyFormTitle .dhx_form-group{
    margin: 0px;
}
.checkIn_alert{
    width: 200px;
}

.faultReportDoneForm_checkBox label{
    margin: 0px;
    margin-top: 6px;
}

.faultReportDoneForm_checkBox label .dhx_text{
    margin: 0px;
}

.keywordSearchDataview .dhx_dataview-item{
    border-radius: 0px;
}
.windowFootAccept{
    margin-right: 0px;
}

.dhx_message--warning {
    background-color: #e79f41;
}

.dhx_message--warning .dhx_message__icon, .dhx_message--warning .dhx_message__text {
    color: #fff;
}