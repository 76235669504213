.dhxRoomDeviceWindow1 .dhx_window-footer,.dhxRoomDeviceWindow2 .dhx_window-footer,
.dhxRoomDeviceWindow3 .dhx_window-footer,.mainProductSetWindow .dhx_window-footer,.mainProductSetWindow2 .dhx_window-footer,
.mainProductSetWindow3 .dhx_window-footer{
    padding-top: 0;
}

.dhxRoomDeviceWindow1 .dhx_window-content,.dhxRoomDeviceWindow2 .dhx_window-content,
.dhxRoomDeviceWindow3 .dhx_window-content,.mainProductSetWindow .dhx_window-content,.mainProductSetWindow2 .dhx_window-content
,.mainProductSetWindow3 .dhx_window-content{
    padding-bottom: 0;
}

.dhxRoomDeviceWindow1 .dhx_window-header,.dhxRoomDeviceWindow2 .dhx_window-header,
.dhxRoomDeviceWindow3 .dhx_window-header,.mainProductSetWindow .dhx_window-header,.mainProductSetWindow2 .dhx_window-header
,.mainProductSetWindow3 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.mainProductSetWindow .dhx_form-group{
    margin-bottom: 3px;
}

.noLabel .dhx_label{
    height: 20px;
}

.noLabel{
    text-align: center;
}