.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.stopFormTitle input{
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}
.testgroup > .dhx_form-element{
    flex: 1 1 auto !important;
}

.dhx_widget--bordered{
    border: none;
}
.dhx_dataview-item{
    border-radius: 5px;
}

.dhx_dataview-item--selected {
    box-shadow: inset 0 0 0 3px #0288d1;
}
.dhx_layout-resizer--y {
    border: 3px #dcd4d4 solid;
}

.dxi.dxi-dots-horizontal:before {
    content: "\F11E";
}

.accCtlSetToolbar>.dhx_layout-cell-header--row>.dhx_layout-cell-header__title{
    font-size: 1.4rem;
    font-weight: 800;
}



.dhxDoorLockWindow2 .dhx_window-footer,
.dhxHotelLockSetWindow1 .dhx_window-footer,
.dhxKeywordSearchWindow1 .dhx_window-footer,
.dhxPasswordWindow1 .dhx_window-footer,
.dhxPasswordWindow2 .dhx_window-foote{
    padding-top: 0;
}

.dhxDoorLockWindow2 .dhx_window-content,
.dhxHotelLockSetWindow1 .dhx_window-content,
.dhxKeywordSearchWindow1 .dhx_window-content,
.dhxPasswordWindow1 .dhx_window-content,
.dhxPasswordWindow2 .dhx_window-content{
    padding-bottom: 0;
}

.dhxDoorLockWindow2 .dhx_window-header,
.dhxHotelLockSetWindow1 .dhx_window-header,
.dhxKeywordSearchWindow1 .dhx_window-header,
.dhxPasswordWindow1 .dhx_window-header,
.dhxPasswordWindow2 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.hotelLockSetFormTip input{
    color: red;
    font-weight: 800;
}

.dhxHotelLockSetWindow1 .dhx_form-group{
    margin-bottom: 0px;
}

.dhx_layout-cell-header{
    padding: 8px 0px;
}

.windowFootAccept{
    margin-right: 0px;
}