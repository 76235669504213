.dhxHotelRoomNumberSetWindow .dhx_window-footer,.dhxHotelRoomNumberSetWindow2 .dhx_window-footer,.dhxHotelRoomNumberSetWindow3 .dhx_window-footer{
    padding-top: 0;
}

.dhxHotelRoomNumberSetWindow .dhx_window-content,.dhxHotelRoomNumberSetWindow2 .dhx_window-content,.dhxHotelRoomNumberSetWindow3 .dhx_window-content{
    padding-bottom: 0;
}

.dhxHotelRoomNumberSetWindow .dhx_window-header,.dhxHotelRoomNumberSetWindow2 .dhx_window-header,.dhxHotelRoomNumberSetWindow3 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.dhx_message--warning {
    background-color: #e79f41;
}

.dhx_message--warning .dhx_message__icon, .dhx_message--warning .dhx_message__text {
    color: #fff;
}