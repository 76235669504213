.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.custom-class .dhx_tabbar-tab-button {
    background-color: rgb(234, 238, 238);
    color: #000000;
    border-color: #b4b6b6;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.custom-class .dhx_tabbar-tab-button--active {
    background-color: rgb(2, 136, 209);
    color: white;
}
.custom-class .dhx_tabbar-header-active {
    /*background-color: #ff5252;*/
}
.stopFormTitle input{
    color: #cea26c;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}

.prdSelectGroup > .dhx_form-element{
    flex: 1 1 auto !important;
}

.fa-pen:before, .fa-circle-stop:before, .fa-trash-can:before{
    font-size: 20px;
}

.dhxPaymentMethodWindow1 .dhx_window-footer,
.dhxPaymentMethodWindow2 .dhx_window-footer,
.dhxPaymentMethodWindow3 .dhx_window-footer{
    padding-top: 0;
}

.dhxPaymentMethodWindow1 .dhx_window-content,
.dhxPaymentMethodWindow2 .dhx_window-content,
.dhxPaymentMethodWindow3 .dhx_window-content{
    padding-bottom: 0;
}

.dhxPaymentMethodWindow1 .dhx_window-header,
.dhxPaymentMethodWindow2 .dhx_window-header,
.dhxPaymentMethodWindow3 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.use{
    color:green;
}

.nouse{
    color:red;
}