.DHTMLButton{
    margin: 4px;
    border-radius: 5px;
}
.stopFormTitle input{
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.stopFormTitle{
    width: 100% !important;
}
.stopFormName input{
    font-weight: 800;
}
.deleteFormTitle input{
    color: #c94747;
    font-weight: 800;
    text-align: center;
    font-size: 2em;
}
.deleteFormTitle{
    width: 100% !important;
}
.deleteFormName input{
    font-weight: 800;
}
.testgroup > .dhx_form-element{
    flex: 1 1 auto !important;
}
.dhxDoorLockWindow1 .dhx_window-footer,
.dhxCheckOutWindow1 .dhx_window-footer{
    padding-top: 0;
}

.dhxDoorLockWindow1 .dhx_window-content,
.dhxCheckOutWindow1 .dhx_window-content{
    padding-bottom: 0;
}

.dhxDoorLockWindow1 .dhx_window-content .dhx_form,
.dhxWeekViewGridWindow1 .dhx_window-content .dhx_form{
    padding: 0 !important;
}

.dhxDoorLockWindow1 .dhx_window-header,
.dhxCheckOutWindow1 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

/*.changeBtn button{*/
/*    width: 300px;*/
/*    font-size: 20px;*/
/*}*/

.viewGridFormBlockBtn .dhx_form-group--textinput{
    margin-bottom: 0px;
}