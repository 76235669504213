.dhxInvoiceReportGridWindow1 .dhx_window-footer,
.dhxInvoiceReportGridWindow2 .dhx_window-footer{
    padding-top: 0;
}

.dhxInvoiceReportGridWindow1 .dhx_window-content,
.dhxInvoiceReportGridWindow2 .dhx_window-content{
    padding-bottom: 0;
}

.dhxInvoiceReportGridWindow1 .dhx_window-header,
.dhxInvoiceReportGridWindow2 .dhx_window-header{
    padding-bottom: 0;
    margin-bottom: 0;
}

.mainProductSetWindow .dhx_form-group{
    margin-bottom: 3px;
}

.noLabel .dhx_label{
    height: 20px;
}

.noLabel{
    text-align: center;
}